import React from 'react';
import styled from 'styled-components';

const FullWidth = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  
`;
// Do not place inside a Row component
const FullWidthBanner = ({ children }) => {
  return (
    <FullWidth>
      {children}
    </FullWidth>
  )
}

export default FullWidthBanner;