import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { default as BaseBackgroundImage } from 'gatsby-background-image';

import FullWidth from '../FullWidth';
import colors from '../../styles/theme/colors';

const BackgroundImage = styled(({ noHeight, ...props }) => <BaseBackgroundImage {...props} />)`
  ${props => props.noHeight ?
    `height: 100vh;` :
    `
      height: 35rem;
      ${({ theme }) => `
        ${theme.breakPoints.up('xl')} {
          height: 45rem;
        }
        ${theme.breakPoints.up('xxl')} {
          height: 75rem;
        }
      `}
    `
  }
`;

const FullWidthBackground = ({ data, title = 'background image', children, noHeight = false }) => {
  const backgroundFluidImageStack = [
    `linear-gradient(${colors.grey.transparent}, ${colors.grey.transparent})`,
    data.childImageSharp.fluid,
  ]

  return (
    <FullWidth>
      <BackgroundImage
        noHeight={noHeight}
        Tag="section"
        fluid={backgroundFluidImageStack}
        role="img"
        title={title}
        aria-label="Fullscreen Background"
        backgroundColor={colors.grey.transparent}
      >
        {children}
      </BackgroundImage>
    </FullWidth>
  )
}

FullWidthBackground.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
}

export default FullWidthBackground;